/* Header */
.account-header {
  padding-left: 900px;
  text-align: center;
  background-color: #673ab7;
  padding: 30px;
  color: white;
  text-transform: uppercase;
}

.account-title {
  text-transform: uppercase;
}
.account-header {
  background-color: #673ab7; /* Purple color */
}

.account-header-section {
  padding-top: 30px;
}

.account-image {
  margin: 10px;
  padding-left: 50px;
}
