@keyframes zoomOut {
    0% {
      transform: scale(0.5);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  .itemZoom {
    animation: zoomOut 2s ease-out forwards;
  }
  