/* faqList.css */

/* Right Column Style */
.rightColumn {
    padding: 0px 96px;
  }
  
  /* Category Header Style */
  .categoryHeader {
    background: #6a3fb2;
    padding: 10px;
    font-size: 40px;
    font-weight: bold;
    color: white;
    opacity: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Title Style */
  .title {
    font-size: 30px;
    color:'red'
  }
  
  /* List Item Style */
  .listItem {
    display: flex;
    justify-content: space-between;
    color: rgba(63, 37, 106, 1);
    padding: 15px;
    border-bottom: 2px solid rgba(106, 63, 178, 1);
    border-radius: 7px;
    font-size: 30px;
    align-items: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .listItem:hover {
    background-color: rgba(106, 63, 178, 0.1);
  }
  
  /* Expand Button Style */
  .expandButton {
    transform: rotate(0deg);
    margin-left: auto;
    transition: transform 0.25s ease-in-out;
  }
  
  /* Style for when the button indicates the item is expanded */
  .expandOpen {
    transform: rotate(180deg);
  }
  
  /* Collapse Content Style */
  .collapseContent {
    font-size: 20px;
    padding-left: 20px;
    margin: 0px 25px 0px 25px;
  }
  