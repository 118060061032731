.navigation-menu {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  margin: 0;
  padding: 0;
}

.navigation-menu li {
  margin: 0px 15px;
  padding: 10px 0;
}

.navigation-menu li a {
  text-decoration: none;
  color: #757575;
  transition: color 0.2s ease-in-out;
}

.navigation-menu li a:hover,
.navigation-menu li a.active {
  color: #673AB7;
}

.mobile-navigation li {
  margin-top: 20px;
}

.mobile-navigation li a {
  text-decoration: none;
  font-size: 1.2rem;
  color: #757575;
}
