.home {
  height: 60vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-image: linear-gradient(to left, rgba(0,0,0,0.1), rgba(0,0,0,0.85)), var(--banner-image);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.headerContainer {
  width: auto;
  margin-left: 200px;
  color: white;
}

.typography {
  margin-bottom: 30px;
}

.enterButton {
  width: 250px;
  max-width: 250px;
  height: 75px;
  margin-top: 1% !important;
  padding-top: 50px;
  font-size: 1.7em !important;;
}

.container {
  padding: 80px;
  max-width: 70%;
}

.section {
  margin-bottom: 20px;
}

.image250px {
  height: 250px;
}

.strongWeight {
  font-weight: 600;
}

.marginBottom1em {
  margin-bottom: 1em;
}

.fontSize1rem {
  font-size: 1rem;
}

/* Mobile View */
@media (max-width: 600px) {
  .home {
    align-items: center;
  }

  .headerContainer {
    margin-left: 0;
    border-radius: 10px;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    color: white;
  }

  .button {
    background-color: white;
    color: #121619;
    margin-bottom: 30px;
  }
}
