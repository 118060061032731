div .topTitle {
    padding: 20px;
    padding-top: 50px;
    font-size: 35px;
    font-weight: bold;
    color: #6a3fb2;
    opacity: 0.9;
    display: flex;
    justify-content: center;
    align-items: center;
}

.moduleContainer {
    max-width: 900px;
    margin: auto;
    flex-grow: 1;
}

.imageContainer {
    height: 800px;
    overflow-y: auto;
    position: relative;
}

.textOverImage {
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 5px 10px;
    border-radius: 5px;
}

.moduleImage {
    display: block;
    width: 100%;
}

.mobileStepper {
    position: static;
}

.nextButton, .backButton {
    /* Styles if any specific to buttons */
}

/* Additional styles for positioning and background of labels on images */
.labelOnImage {
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 5px 10px;
    border-radius: 5px;
}
